import React, { useEffect, useState } from 'react';


const sourceUrl = 'https://bitbucket.org/stapostleandrew/site-cdn/raw/HEAD/liturgic-schedule-ro';
const months = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'];
const today = new Date();
const currentMonth = today.getMonth();
const currentYear = today.getFullYear();

const parseScheduleLine = (line) => {
  const [ localizedDate, ...rest ] = line.split(':');
  const [ day, localizedMonth ] = localizedDate.split(', ').slice(1).join(',').split(' ');
  const monthIndex = months.indexOf(localizedMonth); // may want to use .find() in the future to alleviate casing issues
  const date = new Date(currentYear, monthIndex, day);
  const monthDelta = currentMonth - monthIndex;
  if (monthDelta > 5) {
    // this is likely next year
    date.setFullYear(currentYear + 1);
  }

  let [ description, joinedTimes ] = rest.join(':').split(': ');
  if (!joinedTimes) {
    joinedTimes = description;
    description = '';
  }

  return { 
    date, 
    localizedDate,
    description,
    times: joinedTimes.split(';').filter(time => !!time)
  };
};

const parseSchedule = (data, startDate, endDate) => {
  return data.split('\n').filter(
    line => !!line
  ).map(parseScheduleLine).filter(
    line => {
      if (startDate && line.date < startDate) return false;
      if (endDate && line.date > endDate) return false;
      return true;
    }
  );
};



const LiturgicSchedule = ({
  startDate,
  endDate
}) => {

  const [data, setData] = useState(null);
  
  useEffect(
    () => {
      const fetchData = async () => {
        const response = await fetch(`${sourceUrl}?t=${Date.now()}`);
        const text = await response.text();
        setData(text);
      }
      fetchData();
    },
    [startDate, endDate]
  );
  const lines = data ? parseSchedule(data, startDate, endDate) : [];

  if (!data) {
    // still loading
    return (
      <>
        <div>Loading... / Se încarcă...</div>
      </>
    );
  }

  return (
    <>
      {
        lines.map(
          (line, index) => (
            <div key={index}>
              <h4>{line.localizedDate}</h4>
              <div><b>{line.description}</b></div>
              {
                line.times.map(
                  (time, index2) => (
                    <div key={index2}>{time}</div>
                  )
                )
              }
            </div>
          )
        )
      }
    </>
  );
};

export default LiturgicSchedule;
