import * as React from 'react';
import { Link } from 'gatsby';


const MainMenu = () => {

  return (
    <div className="main-menu">
      <div className="main-menu-wrapper">
        <Link to="/">Acasă</Link>
        <Link to="/donatie">Donații</Link>
        <Link to="/program-liturgic">Program Liturgic</Link>
        <Link to="/activitati">Activități</Link>
        <Link to="/articole">Articole</Link>
        <Link to="/general/scoala-duminica">Școala de duminică</Link>
        <Link to="/articole/despre-noi">Despre noi</Link>
        <Link to="/contact">Contact</Link>
      </div>
    </div>
  );
};

export default MainMenu;
