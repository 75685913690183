import React, { useState, useEffect } from 'react';


const DailyIcon = () => {

  const [displayDate, setDisplayDate] = useState(null);
  const [iconUrl, setIconUrl] = useState(null);
  const [textUrl, setTextUrl] = useState(null);
  const [soundUrl, setSoundUrl] = useState(null);

  const updateState = (date) => {
    setDisplayDate(date.toLocaleDateString('ro'));
    const imageIndex = `${date.getDate()}_${date.getMonth() + 1}`;
    setIconUrl(`https://www.sinaxar.ro/${imageIndex}.jpg`);
    setTextUrl(`https://www.sinaxar.ro/${imageIndex}.gif`);
    const monthName = date.toLocaleString('default', { month: 'short' });
    const soundIndex =`${date.getDate()}${monthName.replace('.', '').toLowerCase()}`;
    setSoundUrl(`https://sinaxar.ro/${soundIndex}.mp3`);
  }

  useEffect(
    () => {
      updateState(new Date());
    },
    [ displayDate ]
  );

  return (
    <div className="insert" style={{ textAlign: 'center' }}>
      <div>
        <small>{displayDate}</small>
      </div>
      <div>
        <img src={iconUrl} alt="icoana zilei" />
      </div>
      <div>
        <img src={textUrl} alt="sinaxar" />
      </div>
      <div>
        { // eslint-disable-next-line jsx-a11y/media-has-caption 
        } <audio controls src={soundUrl} style={{ width: '100%' }}>
        </audio>
      </div>
    </div>
  );
};

export default DailyIcon;
