import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PostList from './post-list';
import { beginningOfDay} from '../util';


const Activities = () => {


  const startDate = beginningOfDay();

  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {fields: {slug: {regex: "^/activitati/"}}}
          sort: { fields: [frontmatter___date], order: ASC }
        ) {
          nodes {
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "D MMMM YYYY", locale: "ro")
              rawDate: date
              description
            }
            excerpt
          }
        }  
      }
    `
  );

  const posts = allMarkdownRemark.nodes.filter(
    node => !node.frontmatter.rawDate || new Date(node.frontmatter.rawDate) >= startDate
  );

  return (
    <div>
      <h1>Activități</h1>
      <PostList posts={posts} />
    </div>
  );
};

export default Activities;
