import * as React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { beginningOfDay } from '../util';


const ActivitiesBox = () => {


  const startDate = beginningOfDay();

  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {fields: {slug: {regex: "^/activitati/"}}}
          sort: { fields: [frontmatter___date], order: ASC }
        ) {
          nodes {
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "D MMMM YYYY", locale: "ro")
              rawDate: date
              description
            }
            excerpt
          }
        }  
      }
    `
  );

  const nodes = allMarkdownRemark.nodes.filter(
    node => !node.frontmatter.rawDate || new Date(node.frontmatter.rawDate) >= startDate
  );

  return (
    <div className="insert">
      <p><b>Activități</b></p>
      <ol style={{ listStyle: `none` }}>
        {
          nodes.map(
            node => (
              <li key={node.fields.slug}>
                <Link to={node.fields.slug}>
                  {node.frontmatter.title}
                </Link>
              </li>
            )
          )
        }
      </ol>
    </div>
  );
};

export default ActivitiesBox;
