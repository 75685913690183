import * as React from 'react';
import MainMenu from './main-menu';


const MainLayout = ({ children }) => {

  return (
    <div className="main-layout">
      <div className="scroll-wrapper">
        <MainMenu />
        <div className="content-wrapper">
          <main>{children}</main>
          <footer>
            <hr />
            © {new Date().getFullYear()} St Apostle Andrew Romanian Orthodox Church, Mississauga
          </footer>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
