export const beginningOfDay = (date = new Date()) => {
	if (date instanceof Date) {
		const result = new Date(date);
		result.setHours(0);
		result.setMinutes(0);
		result.setSeconds(0);
		result.setMilliseconds(0);
		return result;
	}
	throw new Error('"date" should be a valid date');
};