import * as React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';


const RecentArticles = () => {

  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {fields: {slug: {regex: "^/articole/"}}}
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 4
        ) {
          nodes {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }  
      }
    `
  );
  const { nodes } = allMarkdownRemark;

  return (
    <div className="insert">
      <p><b>Articole recente</b></p>
      <ol style={{ listStyle: `none` }}>
      {
        nodes.map(
          node => (
            <li key={node.fields.slug}>
              <Link to={node.fields.slug}>
                {node.frontmatter.title}
              </Link>
            </li>
          )
        )
      }
      </ol>
    </div>
  );
};

export default RecentArticles;
